<template>
    <div>
        <div class="px-3 py-3 pt-lg-5 pb-lg-4 text-center" ref="packageSelect">
            <div class="sec-head font-weight-bolder">现在开始选择您的套餐吧</div>
        </div>
        <form class="row container justify-content-center text-center" ref="selection">
            <div class="col-lg-4 mb-3">
                <label for="state" class="font-weight-bolder required-star sec-subsubhead">注册州</label>
                <select class="custom-select d-block w-100 clickable" id="state" v-model="state" required="">
                    <Option value="">选择...</Option>
                    <Option value="CA">加利福尼亚州</Option>
                    <Option value="DE">特拉华州</Option>
                </select>
                <div class="invalid-feedback">
                    请选择一个注册州。
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <label for="state" class="font-weight-bolder required-star sec-subsubhead">公司类型</label>
                <select class="custom-select d-block w-100 clickable" id="type" v-model="type" required="">
                    <option value="">选择...</option>
                    <Option value="LLC">有限责任公司</Option>
                    <Option value="CCORP">股份有限公司</Option>
                </select>
                <div class="invalid-feedback">
                    请选择一个公司类型。
                </div>
            </div>
        </form>
        <div class="pricing-header px-3 py-3 pt-lg-5 pb-lg-4 mx-auto text-center">
            <div class="sec-head font-weight-bolder">Pricing</div>
        </div>
        <div class="container">
            <div class="card-deck mb-3 text-center">
                <Price package-name="$348产品包" default-fee="98" class="mx-2" :state="state" :type="type" package="BASIC"
                       @validate="validate">
                    <ul class="packageItem mt-3 mb-4">
                        <li>美国公司注册证书-加州或者特拉华州</li>
                        <li>美国公司名称重复检测</li>
                        <li>美国联邦税号注册</li>
                        <li>美国公司首年注册地址代理</li>
                        <li>美国公司首年年审</li>
                        <li>美国公司企业章程</li>
                        <li>专业律师审核</li>
                    </ul>
                </Price>
                <Price package-name="$998产品包" default-fee="348" class="mx-2" :state="state" :type="type"
                       package="PREMIUM" @validate="validate">
                    <ul class="packageItem mt-3 mb-4">
                        <li>美国公司注册证书-加州或者特拉华州</li>
                        <li>美国公司名称重复检测</li>
                        <li>美国联邦税号注册</li>
                        <li>美国公司首年注册地址代理</li>
                        <li>美国公司首年年审</li>
                        <li>美国公司企业章程</li>
                        <li>美国公司股东协议</li>
                        <li>美国公司员工保密协议</li>
                        <li>美国公司员工手册</li>
                        <li>美国公司员工雇佣合同</li>
                        <li>专业律师指导</li>
                    </ul>
                </Price>
                <Price package-name="$2998产品包" default-fee="998" class="mx-2" :state="state" :type="type"
                       package="CUSTOM" @validate="validate">
                    <ul class="packageItem mt-3 mb-4">
                        <li>美国公司注册证书-加州或者特拉华州</li>
                        <li>美国公司名称重复检测</li>
                        <li>美国联邦税号注册</li>
                        <li>美国公司首年注册地址代理</li>
                        <li>美国公司首年年审</li>
                        <li>美国营业州税号注册</li>
                        <li>外州营业许可证（一个）</li>
                        <li>美国公司股东协议</li>
                        <li>美国公司企业章程</li>
                        <li>美国公司员工股权分配合同</li>
                        <li>美国公司员工保密协议</li>
                        <li>美国公司员工手册</li>
                        <li>美国公司员工雇佣合同</li>
                        <li>美国公司常用合同-房屋租赁，外包服务等</li>
                        <li>融资指导咨询</li>

                    </ul>
                </Price>
            </div>
        </div>
    </div>
</template>

<script>
  import Price from '@/components/Price'
  import jq from 'jquery'

  export default {
    name: "Pricing",
    data () {
      return {
        state: "",
        type: ""
      }
    },
    components: {Price},
    created () {
      if (this.$route.query.state !== undefined) {
        localStorage.state = this.$route.query.state;
      }
      if (this.$route.query.type !== undefined) {
        localStorage.type = this.$route.query.type;
      }
      if (localStorage.state) {
        this.state = localStorage.state;
      }
      if (localStorage.type) {
        this.type = localStorage.type;
      }
      window.scrollTo(0, 0)
    },
    methods: {
      validate () {
        this.$refs.selection.classList.add('was-validated');
        window.scrollTo(0, jq('.was-validated :invalid').offset().top)
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-header {
        background-color: rgba(54, 77, 110, 1.0);
        color: white;
    }

    .required-star::after {
        content: " *";
        color: red;
    }

    .text-muted {
        color: white !important;
    }

    .yellow {
        color: rgba(242, 204, 79, 1.0);
    }

    .yellowBtn {
        color: rgba(54, 77, 110, 1.0);
        background-color: rgba(242, 204, 79, 1.0);
        border: none;
    }

    .packageItem {
        padding: 10px 20px;
        text-align: left;
    }

    .row {
        margin: 20px auto;
    }

    .text-muted {
        font-size: 65%;
    }

    .sec-head {
        font-size: 1.5rem;
    }

    .sec-subhead {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
        .sec-head {
            font-size: 2.625rem;
        }

        .sec-subhead {
            font-size: 2rem;
        }

        .sec-subsubhead {
            font-size: 1.625rem;
        }
    }

    .card {
        border-radius: 12px;
    }

    .card-header {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
</style>
